import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

// const url = process.env.REACT_APP_MDMS;
const url = 'http://192.168.100.125:8012';

export const mdmsApi = createApi({
  reducerPath: 'mdmsApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    //project level
    getProjectInfoList: builder.query({
      query: (params) => ({
        url: `${url}/get_project_info`,
        params: params,
      }),
    }),
    getProjectOperationalStatistics: builder.query({
      query: (params) => ({
        url: `${url}/get_project_operational_statistics`,
        params: params,
      }),
    }),
    getProjectRechargeDetail: builder.query({
      query: (params) => ({
        url: `${url}/get_project_recharge_detail`,
        params: params,
      }),
    }),
    getProjectConsumerDetail: builder.query({
      query: (params) => ({
        url: `${url}/get_project_consumer_detail`,
        params: params,
      }),
    }),
    //site level
    getSiteInfoList: builder.query({
      query: (params) => ({
        url: `${url}/get_site_info`,
        params: params,
      }),
    }),
    getSiteOperationalStatistics: builder.query({
      query: (params) => ({
        url: `${url}/get_site_operational_statistics`,
        params: params,
      }),
    }),
    getSiteRechargeDetail: builder.query({
      query: (params) => ({
        url: `${url}/site_level_recharge_history`,
        params: params,
      }),
    }),
    getSiteConsumerDetail: builder.query({
      query: (params) => ({
        url: `${url}/site_level_consumer_detail`,
        params: params,
      }),
    }),
    // user level
    getConsumerInfoList: builder.query({
      query: (params) => ({
        url: `${url}/consumer_level_detail`,
        params: params,
      }),
    }),
    getConsumerOperationalStatistics: builder.query({
      query: (params) => ({
        url: `${url}/consumer_level_statistics_detail`,
        params: params,
      }),
    }),
    // user detail level
    getConsumerRechargeData: builder.query({
      query: (params) => ({
        url: `${url}/consumer_recharge`,
        params: params,
      }),
    }),
    getConsumerRechargeHistoryData: builder.query({
      query: (params) => ({
        url: `${url}/consumer_recharge_history`,
        params: params,
      }),
    }),
    getBillingHistoryData: builder.query({
      query: (params) => ({
        url: `${url}/consumer_billing_history`,
        params: params,
      }),
    }),
  }),
});
export const {
  //project list
  useGetProjectInfoListQuery,
  useGetProjectOperationalStatisticsQuery,
  useLazyGetProjectRechargeDetailQuery,
  useLazyGetProjectConsumerDetailQuery,
  //site list
  useGetSiteInfoListQuery,
  useGetSiteOperationalStatisticsQuery,
  useLazyGetSiteRechargeDetailQuery,
  useLazyGetSiteConsumerDetailQuery,
  //consumer list
  useGetConsumerInfoListQuery,
  useGetConsumerOperationalStatisticsQuery,
  // consumer detail list
  useGetConsumerRechargeDataQuery,
  useGetBillingHistoryDataQuery,
  useLazyGetConsumerRechargeHistoryDataQuery,
} = mdmsApi;
