import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  GISMapsInitCenterData: {
    responseData: [],
    callAPI: true,
  },
  GISMapsData: {
    responseData: [],
    callAPI: true,
  },
  GISFilterData: {
    responseData: [],
    callAPI: true,
  },
  GISDTRFilterSelected: {
    responseData: [],
    callAPI: true,
  },
  GISFilterSelected: {
    responseData: [],
    callAPI: true,
  },
  GISMapsMarkerData: {
    responseData: [],
    callAPI: true,
  },
  GISMapsPolyLineData: {
    responseData: [],
    callAPI: true,
  },
  GISMapZoom: {
    responseData: 13,
    callAPI: true,
  },
  GISMapUpdatedCenter: {
    responseData: {},
    centerUpdated: false,
  },
};

const utilityGISMapsSlice = createSlice({
  name: 'utilityGISMaps',
  initialState,
  reducers: {
    setUtilityGISMapsInitCenterData: (state, action) => {
      state.GISMapsInitCenterData.responseData = action.payload;
      console.log(state.GISMapsInitCenterData.responseData, ' thi si sstate');
      state.GISMapsInitCenterData.callAPI = action.payload.callAPI;
    },
    setUtilityGISMapsData: (state, action) => {
      console.log(action, ' this is payloaed ');
      state.GISMapsData.responseData = action.payload.data;
      state.GISMapsData.callAPI = action.payload.callAPI;
    },
    setUtilityGISFilterData: (state, action) => {
      state.GISFilterData.responseData = action.payload;
      state.GISFilterData.callAPI = action.payload.callAPI;
    },
    setUtilityGISDTRFilterSelected: (state, action) => {
      state.GISDTRFilterSelected.responseData = action.payload;
      state.GISDTRFilterSelected.callAPI = action.payload.callAPI;
    },
    setUtilityGISFilterSelected: (state, action) => {
      state.GISFilterSelected.responseData = action.payload;
      state.GISFilterSelected.callAPI = action.payload.callAPI;
    },
    setUtilityGISMapsMarkerData: (state, action) => {
      state.GISMapsMarkerData.responseData = action.payload;
      state.GISMapsMarkerData.callAPI = action.payload.callAPI;
    },
    setUtilityGISMapsPolyLineData: (state, action) => {
      state.GISMapsPolyLineData.responseData = action.payload;
      state.GISMapsPolyLineData.callAPI = action.payload.callAPI;
    },
    setUtilityGISMapZoom: (state, action) => {
      state.GISMapZoom.responseData = action.payload;
      state.GISMapZoom.callAPI = action.payload.callAPI;
    },
    setUtilityGISMapUpdatedCenter: (state, action) => {
      state.GISMapUpdatedCenter.responseData = action.payload;
      state.GISMapUpdatedCenter.centerUpdated = action.payload.centerUpdated;
    },
  },
});

export const {
  setUtilityGISMapsInitCenterData,
  setUtilityGISMapsData,
  setUtilityGISFilterData,
  setUtilityGISDTRFilterSelected,
  setUtilityGISFilterSelected,
  setUtilityGISMapsMarkerData,
  setUtilityGISMapsPolyLineData,
  setUtilityGISMapZoom,
  setUtilityGISMapUpdatedCenter,
} = utilityGISMapsSlice.actions;

export default utilityGISMapsSlice.reducer;
